<template>
  <List
    :title="'Category'"
    :entries="bodyItems"
    :headItems="headItems"
    :hasCheck="false"
    :hasFilters="false"
    :hasCreate="false"
    :canCreate="canCreate"
    :loaded="loaded"
    :toFilter="toFilter"
    :tabFilters="tabFilters"
    :isActive="isActive"
    :total="total"
    :perPage="perPage"
    :currentPage="currentPage"
    @optionSel="handleOption"
    @create="handleNew"
    @filters="filterByItem"
    @actions="handleAction"
    @search="handleSearch"
  />
  <Pagination
    :entries="entries"
    :currentPage="currentPage"
    :total="total"
    :perPage="perPage"
    :pages="pages"
    @goToFirst="currentPage = 1"
    @goToLast="currentPage = pages"
    @goToPage="handlePage"
    @changePage="handlePageChange"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { GET_USER_PROFILE_GETTER } from "@/store/storeconstants";
import axiosInstance from "@/services/AxiosTokenInstance";
import List from "@/components/List/Index.vue";
import Pagination from "@/components/List/Pagination.vue";
import timeSince from "@/mixins/timeSince";
import api from "@/mixins/optionsApi";

export default {
  title() {
    return `Remote Assistance | ${this.title}`;
  },
  data() {
    return {
      title: "Guides",
      entries: [],
      currentPage: 1,
      filteredEntries: [],
      total: 0,
      pages: 0,
      perPage: 10,
      imgURL: process.env.VUE_APP_DO_SPACES,
      loaded: false,
      filter: [],
      canCreate: [4, 5, 6],
      headItems: ["Category", "Nº FAQS"],
      bodyItems: [],
      editOptions: ["see", "edit"],
      search_key: "",
      hasOptions: false,
      see: false,
      editItem: false,
      create: false,
      selItem: 0,
      client: localStorage.getItem("client")
        ? JSON.parse(localStorage.getItem("client"))
        : null,
      tabFilters: [
        {
          name: "Categories",
          filter: "",
          isActive: true,
        },
      ],
      isActive: 0,
    };
  },

  provide() {
    return {
      edit: this.editOptions,
      hasOptions: this.hasOptions,
    };
  },

  components: {
    List,
    Pagination,
  },

  mixins: [timeSince, api],

  watch: {
    currentPage: {
      immediate: true,
      handler(val) {
        this.getData(val);
      },
    },

    filter() {
        if (this.currentPage !== 1) {
            this.currentPage = 1
            return
        }
        this.getData(1);
    },

    entries() {
      this.fillBodyItems();
    },

    search_key() {
        if (this.currentPage !== 1) {
            this.currentPage = 1
            return
        }
        this.getData(1);
    },
  },

  computed: {
    ...mapGetters("account", {
      getUserProfile: GET_USER_PROFILE_GETTER,
    }),
    currentTotal() {
      return this.total > 10 && this.perPage * this.currentPage < this.total
        ? this.perPage * this.currentPage
        : this.total;
    },
    toFilter() {
      return [];
    },
    toEdit() {
      return [];
    },
  },
  methods: {
    handlePageChange(to) {
      switch (to) {
        case "less":
          this.currentPage -= 1;
          break;
        case "more":
          this.currentPage += 1;
          break;
      }
    },

    handlePage(to) {
        this.currentPage = to
    },

    fillBodyItems() {
      this.bodyItems = this.entries.map((item) => {
        return [
          {
            template: "normal",
            id: item.id,
            data: item.name || "undefined",
          },
          {
            template: "normal",
            data: item.guides_count.toString() || 0,
          },
        ];
      });
    },

    async getData(value) {
      this.entries = [];
      this.perPage = 0;
      this.total = 0;
      this.pages = 0;
      this.currentPage = value;
      this.loaded = false;
      let orgDataString = localStorage.getItem("organization");
      if (orgDataString) {
        let orgData = JSON.parse(orgDataString);
        let response = await axiosInstance
          .get(`category?org_id=${orgData.id}&page=${value}`)
          .finally(() => (this.loaded = true));
        let responseData = response.data.data;
        this.entries = responseData.data;
        this.perPage = Number(responseData.per_page);
        this.total = responseData.total;
        this.pages = response.data.data.last_page;
      }
    },

    async filterByItem() {},

    handleAlert(data) {
      this.emitter.emit("alert", data);
    },

    handleNew() {},

    handleOption(data) {
      this.selItem = data[1];
      const item = this.entries.find((el) => el.id === this.selItem);
      switch (data[0]) {
        case "see":
          this.$router.push({
            path: "/products/guides/" + item.id,
            query: { category: item.name },
          });
          break;

        default:
          break;
      }
    },

    handleAction() {},

    handleSearch() {},
  },
};
</script>